import React from "react";

function Sponsor() {
  return (
    <section
      id="sponsor"
      style={{
        backgroundImage: "url(/img/Page7/bg.png)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      className="h-[720px] md:h-[1020px] lg:h-[1480px] relative"
    >
      {/* <div className="absolute w-full flex flex-col justify-between"> */}
      <div className="absolute w-full flex flex-col justify-between h-full">
        <img
          src="/img/Page7/Awan atas.png"
          className="h-[450px] w-full object-bottom object-cover "
          alt=""
        />
        <img
          src="/img/Page7/Awan bawah.png"
          className="h-[450px] w-full object-top object-cover "
          alt=""
        />
      </div>
      <div className="absolute flex flex-col w-full z-40 h-full justify-center items-center">
        <div className="flex items-center justify-center gap-2 md:gap-4 mb-10 md:mb-20 md:mx-32">
          <h1 className="font-bold font-CinzelDecorative text-[#001C46] text-2xl md:text-3xl lg:text-5xl text-center">
            our sponsor
          </h1>
          <img
            src="/img/Page7/sponsor.png"
            alt=""
            className="w-[20px] md:w-[32px] lg:w-12"
          />
        </div>
        <iframe
          src="https://drive.google.com/file/d/1WtDNQ2B8zoyBgHdqvKFwdIbee-vxOid6/preview"
          title="YouTube video player"
          frameborder="0"
          className="md:h-[315px] md:w-[560px] lg:h-[630px] lg:w-[1020px]"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    </section>
  );
}

export default Sponsor;
