import React from "react";
import Dokumentasi from "./Dokumentasi";

function About() {
  return (
    <section
      id="about"
      style={{ backgroundImage: "url(/img/Page2/bg.png)" }}
      className="h-[500px] md:h-fit lg:h-full lg:min-h-screen"
    >
      <div className="flex flex-col w-full z-50 h-full justify-center items-center md:py-20 lg:py-32">
        <div className="mb-10 lg:mb-20 md:mx-32">
          <h1
            data-aos="fade-up"
            className="font-bold drop-shadow-lg shadow-black text-center font-CinzelDecorative text-2xl md:text-[32px] lg:text-[64px]"
          >
            Throughout The Year
          </h1>
        </div>
        <div data-aos="zoom-in" className="w-1/2 md:w-2/3 lg:w-1/2">
          <Dokumentasi />
        </div>
      </div>
    </section>
  );
}

export default About;
