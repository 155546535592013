import React, { useState } from "react";

const questions = [
  {
    question: "Siapa saja yang dapat mengikuti NMGBC?",
    answer:
      "NMGBC terbuka untuk semua pelajar terbaik SMA/sederajat dari seluruh Indonesia!",
  },
  {
    question: "Lomba apa saja yang terdapat di NMGBC 2024?",
    answer:
      "Health Speech Contest adalah lomba speech yang berbasis bahasa inggris dengan topik respiratory yang dilakukan secara individu.\nMedical and General Biology Competition adalah olimpiade tentang materi biologi dan cuplikan respiratory yang berbasis tim.\nPublic Poster Competition adalah lomba poster yang berbasis tim 2-3 orang yang membahas topik respirasi dan terbuka untuk dibikin dalam bahasa inggris maupun bahasa indonesia.\nUntuk informasi lebih lanjut, bisa dilihat di guideline!",
  },
  {
    question: "Kapan dan dimana NMGBC 2024 dilaksanakan?",
    answer:
      "NMGBC 2024 akan dilaksanakan pada tiga hari yang berbeda yaitu 11 Mei 2024 secara online dan 18-19 Mei 2024 secara offline di Gedung Rumpun Ilmu Kesehatan (RIK), Universitas Indonesia, Depok, Jawa Barat.",
  },
  {
    question: "Materi apa yang akan dilombakan?",
    answer:
      "Tema besar NMGBC 2024 adalah Respiratory, sehingga materi yang akan dibawakan adalah seperti masalah ISPA dan PPOK. Untuk informasi detailnya mengenai masing-masing lomba, Silakan mengakses Guideline",
  },
  {
    question: "Bagaimana cara mendaftar NMGBC 2024?",
    answer:
      "Registrasi bisa dilakukan melalui laman registrasi di www.nmgbc2024.com serta mengikuti alur registrasi yang tertera pada https://bit.ly/NMGBCGUIDELINES",
  },
  {
    question: "Apakah bisa masuk FKUI melalui NMGBC?",
    answer:
      "NMGBC mungkin bisa membantu untuk meningkatkan konsiderasi seleksi sebagai calon mahasiswa FKUI apabila menang maupun ikut menjadi volunteer di NMGBC, namun tidak menjamin siswa tersebut untuk diterima menjadi Mahasiswa FKUI",
  },
  {
    question: "Bagaimana akomodasi NMGBC offline?",
    answer:
      "Pihak NMGBC tidak menyediakan akomodasi, namun kami sangat menyarankan Kartika One Hotel & Bumi Wiyata Hotel. Pihak NMGBC hanya menyediakan transportasi dari dua hotel tersebut ke lokasi pelaksanaan NMGBC 2024",
  },
  {
    question: "Kapan tenggat waktu pendaftaran NMGBC 2024?",
    answer:
      "Periode pendaftaran NMGBC berlangsung sejak 21 Januari 2024 Pukul 15.00 hingga 6 Mei 2024 Pukul 23.59. Dengan rincian sebagai berikut\n- Early Bird Biocomp, HSC, and Pubpos: 21 Januari - 5 April 2024\n- Late Bird Biocomp: 6 April 2024 - 6 Mei 2024\n- Late Bird Pubpos and HSC: 6 April 2024 - 2 Mei 2024",
  },
  {
    question: "Berapa biaya pendaftaran NMGBC 2024?",
    answer:
      "Biaya pendaftaran lomba NMBGC tergantung dengan waktu pendaftaran dan lomba yang akan didaftarkan. Berikut adalah daftar pembayaran lomba NMGBC.\nGeneral Biology and Medical Competition\n   - Early Bird: Rp300.000\n   - Late Bird: Rp350.000\nPublic Poster Competition\n   - Early Bird: Rp300.000\n   - Late Bird: Rp350.000\nHealth Speech Contest\n   - Early Bird: Rp200.000\n   - Late Bird: Rp250.000",
  },
  {
    question: "Apa saja metode pembayaran yang tersedia?",
    answer:
      "Pembayaran hanya dapat dilakukan melalui transfer\n- BCA 7700821212 a.n Reynaldo Alifiandro Marcelino (Biocomp/Pubpos/HSC))\n- BCA 8705325002 a.n.Celine Fayola (Merchandise)",
  },
  {
    question:
      "Apakah Peserta yang Tidak Lolos Semifinal tetap Boleh Mengikuti Mata Acara lain di Day 2 dan Day 3?",
    answer:
      "Seluruh Peserta yang melakukan pendaftaran NMGBC 2024 baik yang lolos ataupun tidak lolos ke semifinal berhak mengikuti mata acara lain di Day 2 dan Day 3 NMGBC 2024, yaitu Workshop, Doctor 101, Faculty Tour, dan Medical Seminar.\nKhusus bagi peserta Biocomp dan HSC yang lolos ke semifinal dan Public Poster yang lolos ke Final, diwajibkan untuk mengikuti mata acara lain di Day 2 dan Day 3 , yaitu Workshop, Doctor 101, Faculty Tour, dan Medical Seminar.",
  },
];

function FAQ() {
  const [accordion, setActiveAccordion] = useState(-1);

  function toogleAccordion(index) {
    if (index === accordion) {
      setActiveAccordion(-1);
      return;
    }
    setActiveAccordion(index);
  }

  return (
    <section
      id="faqs"
      style={{ backgroundImage: "url(/img/Page6/bg.png)" }}
      className="min-h-screen relative"
    >
      <div className="absolute w-full">
        <img
          src="/img/Page6/Atap atas.png"
          className="h-[100px] w-full object-bottom object-cover"
          alt=""
        />
      </div>

      <div className="flex flex-col items-center pb-20 md:pb-32">
        <div
          data-aos="fade-up"
          className="flex items-center justify-center gap-2 md:gap-4 mb-10 md:mb-20 mt-32 mx-32"
        >
          <h1 className="font-bold font-CinzelDecorative text-[#4D3D39] text-2xl md:text-3xl lg:text-5xl text-center">
            FAQs
          </h1>
          <img
            src="/img/Page6/faq.png"
            alt=""
            className="w-[24px] md:w-[32px] lg:w-[54px]"
          />
        </div>
        <div className="flex w-3/4 md:w-2/3 flex-col gap-2 md:gap-6">
          {questions.map((item, index) => (
            <div
              data-aos="zoom-out-right"
              className="cursor-pointer space-y-1"
              key={index}
            >
              <div
                className={`bg-[#CDD19C] border-2 border-[#4D3D39] rounded-[50px] flex justify-between items-center px-4 py-2 md:px-6 md:py-3`}
                onClick={() => toogleAccordion(index)}
              >
                <div className="flex items-center justify-between w-full gap-2">
                  <h3 className="text-sm md:text-base">{item.question}</h3>
                  {accordion === index ? (
                    <p className="md:text-2xl">-</p>
                  ) : (
                    <p className="md:text-2xl">+</p>
                  )}
                </div>
              </div>
              <div
                className={`bg-[#CDD19C] border-2 border-[#4D3D39] bg-opacity-25 rounded-[25px] p-4 md:p-6 text-xs md:text-sm ${
                  accordion === index ? "block" : "hidden"
                }`}
              >
                <div className="space-y-4">
                  {item.answer.split("\n").map((ans) => (
                    <p>{ans}</p>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* <div className="absolute w-full flex justify-between bottom-0">
        <img
          src="/img/Page3/Bunga kiri.png"
          className="w-1/2 lg:w-fit"
          alt=""
        />
        <img
          src="/img/Page3/Bunga kanan.png"
          className="w-1/2 lg:w-fit"
          alt=""
        />
      </div> */}
    </section>
  );
}

export default FAQ;
