import React from "react";

function Theme() {
  return (
    <section
      id="theme"
      style={{
        backgroundImage: "url(/img/Page3/bg.png)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      className="min-h-screen relative"
    >
      <div className="absolute w-full flex h-full flex-col justify-between">
        <div className="flex justify-between w-full">
          <img
            src="/img/Page3/Vines kiri.png"
            className="w-1/2 md:w-fit"
            alt=""
          />
          <img
            src="/img/Page3/Vines kanan.png"
            className="w-1/2 md:w-fit"
            alt=""
          />
        </div>
        <div className="flex justify-between">
          <img
            src="/img/Page3/Bunga kiri.png"
            className="w-1/2 lg:w-fit"
            a
            alt=""
          />
          <img
            src="/img/Page3/Bunga kanan.png"
            className="w-1/2 lg:w-fit"
            a
            alt=""
          />
        </div>
      </div>
      <div className="flex flex-col justify-center items-center h-full py-40">
        <h1
          data-aos="fade-up"
          className="font-Cinzel text-[#2B4424] text-[18px] md:text-[20px] lg:text-[40px] font-bold drop-shadow-md"
        >
          THIS YEAR'S THEME:
        </h1>
        <h2
          data-aos="fade-up"
          className="font-Cinzel text-white text-[20px] md:text-[32px] lg:text-[64px] font-bold drop-shadow-md"
        >
          RESPIRATORY SYSTEM
        </h2>
        <div className="flex flex-col md:flex-row lg:flex-col xl:flex-row xl:items-start items-center md:w-3/4 lg:w-full xl:w-1/2 gap-10 mt-10">
          <img
            data-aos="flip-left"
            src="/img/Page3/paru-paru.png"
            alt=""
            className="w-2/5"
          />
          <div
            data-aos="flip-right"
            className="shadow-md bg-[#D6ECD0] p-6 rounded-3xl w-4/5 md:w-3/5 flex-grow"
          >
            <h3 className="italic font-bold text-[#2B4424] md:text-xl lg:text-2xl mb-2">
              Why respiratory?
            </h3>
            <p className="text-xs md:text-sm lg:text-lg">
              This theme was raised to raise awareness of medical problems
              related to respiratory conditions in Indonesia, such as COPD and
              ARI which are now still a big issue in Indonesia. Therefore, we
              wish that the NMGBC event can increase our awareness in
              Respiratory System issues while also increasing the spirit of
              competition and learning biology and medicine amongst high school
              students.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Theme;
