import React, { useEffect } from "react";
import "./App.css";
import RoutesConfig from "./config/RoutesConfig";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="w-screen">
      <Navbar />
      <RoutesConfig />
      <Footer />
    </div>
  );
}

export default App;
