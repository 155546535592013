import React from "react";
import TimelineCard from "./TimelineCard";
import "./Timeline.css";

const timelines = [
  { event: "Early Bird Registration", period: "January 21 - April 6, 2024" },
  {
    event: "Late Bird Registration (Public Poster & Health Speech Contest)",
    period: "April 6 - May 2, 2024",
  },
  {
    event: "Late Bird Registration (Medical & General Biology Competition)",
    period: "April 6 - May 6, 2024",
  },
  { event: "Scoring Period (Public Poster)", period: "April 22 - May 7, 2024" },
  {
    event: "Scoring Period (Health Speech Contest Video)",
    period: "May 2-10, 2024",
  },
  {
    event: "Close Submission (Public Poster & Health Speech Contest Video)",
    period: "May 2, 2024",
  },
  {
    event:
      "Close Late Bird Registration (Medical & General Biology Competition)",
    period: "May 6, 2024",
  },
  { event: "Technical Meeting 1", period: "May 8, 2024" },
  {
    event: "Announcement (Public Poster & HSC Semifinalists)",
    period: "May 8, 2024",
  },
  { event: "Online Voting (Public Poster & HSC)", period: "May 8-18, 2024" },
  { event: "Grand Opening NMGBC 2024", period: "May 11, 2024" },
  {
    event: "Preliminary Round (Medical & General Biology Competition)",
    period: "May 11, 2024",
  },
  { event: "Semifinal Round (Public Poster)", period: "May 11, 2024" },
  {
    event:
      "Announcement (Public Poster Finalists, Medical & General Biology Competition Semifinalist and HSC Semifinalist)",
    period: "May 11, 2024",
  },
  { event: "Technical Meeting 2", period: "May 11, 2024" },
  {
    event: "Semifinal Round (Medical & General Biology Competition & HSC)",
    period: "May 18, 2024",
  },
  {
    event:
      "Final Round (Medical & General Biology Competition, Public Poster, and HSC)",
    period: "May 19, 2024",
  },
  {
    event:
      "Grand Closing & Winner Announcement (Medical & General Biology Competition, Public Poster, and HSC)",
    period: "May 19, 2024",
  },
];

function Timeline() {
  return (
    <>
      <section
        id="timeline"
        style={{
          backgroundImage: "url(/img/Page4/bg.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="min-h-screen flex flex-col justify-center md:py-16"
      >
        <div
          data-aos="fade-up"
          className="gap-2 md:gap-4 flex w-full items-center justify-center"
        >
          <img
            src="/img/Page3/Calendar_Icon.png"
            className="w-[24px] md:w-[28px] lg:w-[54px]"
            alt=""
          />
          <h1 className="font-CinzelDecorative text-[#3B4F20] text-2xl md:text-[32px] lg:text-[64px]">
            Timeline
          </h1>
          <img
            src="/img/Page3/Calendar_Icon.png"
            className="w-[24px] md:w-[28px] lg:w-[54px]"
            alt=""
          />
        </div>
        <div className="p-8 flex justify-center">
          <div className="relative md:w-full">
            <div className="line"></div>
            <div className="flex flex-col gap-4">
              {timelines.map((timeline) => (
                <TimelineCard data={timeline} />
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Timeline;
