import React from "react";

function TimelineCard({ data }) {
  return (
    <div
      data-aos="zoom-out-up"
      className="section flex ease-in-out delay-500 relative z-[1] md:mx-12 md:p-4 rounded-2xl items-center md:justify-start md:odd:flex-row-reverse"
    >
      <div className="absolute block h-4 w-4 lg:h-8 lg:w-8 rounded-full bg-[#647C43] left-[4px] md:left-1/2 -translate-x-1/2"></div>
      <div className="content ml-6 md:ml-0 w-full">
        <div className="bg-[#CDD19C] rounded-[50px] px-6 py-2 lg:px-10 lg:py-5 w-full lg:w-2/3">
          <h2 className="font-bold md:text-base lg:text-3xl">{data.event}</h2>
          <p className="text-xs md:text-sm lg:text-lg">{data.period}</p>
        </div>
      </div>
    </div>
  );
}

export default TimelineCard;
