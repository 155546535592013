import React from "react";
import { PiInstagramLogoLight, PiWhatsappLogoLight } from "react-icons/pi";

function Footer() {
  return (
    <div className="bg-[#FFF6EE] px-6 md:h-[120px] h-[60px] border-black border-t-2 flex items-center justify-between font-Crusoe text-xs md:text-sm lg:text-base ">
      © NMGBC 2024 | AMSA University of Indonesia
      <div className="flex items-center text-2xl md:text-3xl gap-2">
        <a
          target="blank"
          href="https://www.instagram.com/nmgbc_2024?igsh=MWE3cDJjbHh2Y24zeg=="
        >
          <PiInstagramLogoLight />
        </a>
        <a
          target="blank"
          href="https://wa.me/628118163536?text=Halo%20Kak%2C%20Saya%20ingin%20bertanya%20mengenai%20NMGBC%202024."
        >
          <PiWhatsappLogoLight />
        </a>
      </div>
    </div>
  );
}

export default Footer;
