import React from "react";
import { Link } from "react-router-dom";

const competitions = [
  {
    name: "Medical & General Biology Competition",
    img: "/img/Page5/Biotech.png",
    desc: "General Biology and Medical Competition is a national olympiad that is open to all highschool students who are interested in learning more about respiratory diseases and biology.",
  },
  {
    name: "Public Poster Competition",
    img: "/img/Page5/GlossPaper.png",
    desc: "Public poster competition helps to raise awareness with respiratory diseases in the form of posters to be shared with the community as an social act from highschool students.",
  },
  {
    name: "Health Speech Competition",
    img: "/img/Page5/VoiceRecognition.png",
    desc: "Health speech competition is a speech competition centered to improve public speaking skills on respiratory diseases to raise awareness on the topic and show the urgency towards respiration as a topic.",
  },
];
function Competition() {
  return (
    <section
      id="competitions"
      style={{
        backgroundImage: "url(/img/Page5/bg.png)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      className="min-h-screen relative pt-20"
    >
      <div
        data-aos="fade-up"
        className="bg-[#83B3D8] bg-opacity-75 flex items-center justify-center gap-4 mx-10 lg:mx-20 rounded-[50px]"
      >
        <h1 className="font-CinzelDecorative text-[#001C46] opacity-100 text-[24px] md:text-[32px] lg:text-[64px] drop-shadow-lg py-2 lg:py-0">
          Competitions
        </h1>
        <img
          src="/img/Page5/stethoscope.png"
          alt=""
          className="h-full w-[24px] md:w-[30px] lg:w-[60px]"
        />
      </div>
      <div className="bg-[#83B3D8] bg-opacity-75 mx-10 lg:mx-20 rounded-[50px] mt-8 flex flex-col items-center gap-4 md:gap-10 px-4 md:px-6 lg:px-10 pt-4 pb-10 md:py-10 lg:py-20">
        <div className="divide-y-2 md:divide-y-0 md:divide-x-2 divide-solid flex flex-col md:flex-row items-center justify-center">
          {competitions &&
            competitions.map((competition) => (
              <div
                data-aos="fade-up"
                className="px-4 py-6 md:p-6 lg:p-10 flex flex-col gap-6"
              >
                <div className="flex md:flex-col lg:flex-row text-center items-center justify-between md:justify-center font-bold md:text-3xl md:gap-2">
                  <img
                    src={competition.img}
                    className="w-1/4 md:w-fit"
                    alt=""
                  />
                  <h2 className="text-sm md:text-base text-center drop-shadow-lg">
                    {competition.name}
                  </h2>
                </div>
                <p className="text-xs md:text-sm lg:text-base text-justify">
                  {competition.desc}
                </p>
              </div>
            ))}
        </div>
        <Link
          data-aos="zoom-in"
          target="blank"
          to={"https://bit.ly/NMGBCGUIDELINES"}
          className="rounded-[50px] border-2 border-black bg-[#FEF8EA] px-3 py-2 md:px-6 md:py-3 text-xs md:text-base lg:text-lg"
        >
          GUIDELINE
        </Link>
      </div>

      <div className="w-full flex flex-col">
        <img src="/img/Page5/Ombak.png" className="" alt="" />
      </div>
    </section>
  );
}

export default Competition;
