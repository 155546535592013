import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AiOutlineLoading } from "react-icons/ai";
import Merchandise from "../components/Merchandise";

export default function Registration() {
  const isClosed = true;

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const onSubmit = (data) => {
    setLoading(true);
    axios
      // .post("http://localhost:8080/api/register/", data, {
      .post(`${process.env.REACT_APP_BACKEND_URL}/register/`, data, {
        headers: {
          "content-type": "application/json",
        },
      })
      .then((response) => {
        alert(
          "Registrasi Berhasil!\n\nSilahkan mengonfirmasi pada narahubung berikut:\n- Fatima Nadine - HSC\n081919021004 (WhatsApp)\n- Dyah Ayu Ardhana - Biocomp\n08119433789 (WhatsApp)\n- Sebastian Pramudji - Pubpos\n081296330355 (WhatsApp)"
        );
        navigate("/");
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            alert(error.response.data.error);
          } else {
            alert("Registrasi Gagal!");
          }
        } else {
          alert("Registrasi Gagal!");
        }
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div
        style={{
          backgroundImage: "url(/img/Registration/bg.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="min-h-screen pt-12 md:pt-20"
      >
        <>
          {loading && (
            <div className="fixed w-full flex items-center justify-center h-full bg-gray-700 opacity-30 -mt-20">
              <AiOutlineLoading
                className="animate-spin opacity-100"
                size={64}
              />
            </div>
          )}
          <h1 className="font-CinzelDecorative text-2xl md:text-4xl lg:text-5xl text-center py-12">
            Registration
          </h1>
          {false ? (
            <div className="flex justify-center pb-32 px-[20px] md:px-[200px]"></div>
          ) : (
            <>
              <div className="flex justify-center px-20 md:px-[200px] mb-10">
                <div className="p-4 bg-[#CDD19C] border-2 border-[#4D3D39]">
                  {isClosed ? (
                    <>
                      <p className="text-center">Registration Closed!</p>
                      <p className="text-center">
                        Thank you for your participation!
                      </p>
                    </>
                  ) : (
                    <>
                      <p>Attention!</p>
                      <li>
                        Untuk lomba Biocomp dan Pubpos wajib mengisi nama tim,
                        HSC diberi (-)
                      </li>
                      <li>Untuk lomba HSC, data diri anggota diberi (-)</li>
                      <li>
                        Untuk lomba Biocomp data diri peserta 3 diberi (-)
                      </li>
                      <li>
                        Untuk lomba Pubpos apabila hanya beranggotakan 2 orang,
                        data diri peserta 3 diberi (-)
                      </li>
                    </>
                  )}
                </div>
              </div>
              {!isClosed && (
                <div className="flex justify-center pb-32 px-20 md:px-[200px]">
                  <form
                    className="flex flex-col text-sm md:text-base max-w-[600px] w-full"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    {/* Pilihan lomba */}
                    <label>Lomba yang Dipilih</label>
                    <select
                      {...register("lomba", { required: true })}
                      className="rounded-full border-2 mb-2 px-1 py-1 md:px-2 md:py-2 border-[#4D3D39] cursor-pointer"
                    >
                      <option value="">Select one</option>
                      <option value="Medical & General Biology Competition">
                        Medical & General Biology Competition
                      </option>
                      {/* <option value="Public Poster Competition">
                      Public Poster Competition
                    </option>
                    <option value="Health Speech Competition">
                      Health Speech Competition
                    </option> */}
                    </select>
                    {/* Nama tim */}
                    <label>Nama Tim</label>{" "}
                    {errors.namatim?.type === "maxLength" && (
                      <span className="text-[#ee1f1f]">
                        Maksimal karakter adalah 50
                      </span>
                    )}
                    <input
                      className={`rounded-full border-2 mb-2 px-2 py-[2px] md:px-3 md:py-1 border-[#4D3D39] ${
                        errors.namatim && "border-[#ee1f1f]"
                      }`}
                      {...register("namatim", {
                        required: true,
                        maxLength: 50,
                      })}
                    />
                    <br />
                    <br />
                    {/* KETUA KELOMPOK */}
                    <h2 className="font-bold mb-2">Ketua Kelompok</h2>
                    {/* Nama */}
                    <label>Nama Lengkap Peserta 1/Ketua</label>{" "}
                    {errors.nama?.type === "maxLength" && (
                      <span className="text-[#ee1f1f]">
                        Maksimal karakter adalah 50
                      </span>
                    )}
                    <input
                      className={`rounded-full border-2 mb-2 px-2 py-[2px] md:px-3 md:py-1 border-[#4D3D39] ${
                        errors.nama && "border-[#ee1f1f]"
                      }`}
                      {...register("nama", { required: true, maxLength: 50 })}
                    />
                    <label>Tempat, Tanggal Lahir Peserta 1/Ketua</label>
                    {errors.ttl?.type === "maxLength" && (
                      <span className="text-[#ee1f1f]">
                        Maksimal karakter adalah 100
                      </span>
                    )}
                    <input
                      className={`rounded-full border-2 mb-2 px-2 py-[2px] md:px-3 md:py-1 border-[#4D3D39] ${
                        errors.ttl && "border-[#ee1f1f]"
                      }`}
                      {...register("ttl", { required: true, maxLength: 100 })}
                    />
                    <label>Jenis Kelamin Peserta 1/Ketua</label>
                    <select
                      {...register("gender", { required: true })}
                      className="rounded-full border-2 mb-2 px-1 py-1 md:px-2 md:py-2 border-[#4D3D39] cursor-pointer"
                    >
                      <option value="">Select one</option>
                      <option value="Laki-laki">Laki-laki</option>
                      <option value="Perempuan">Perempuan</option>
                    </select>
                    <label>Asal Sekolah Peserta 1/Ketua</label>
                    {errors.sekolah?.type === "maxLength" && (
                      <span className="text-[#ee1f1f]">
                        Maksimal karakter adalah 50
                      </span>
                    )}
                    <input
                      className={`rounded-full border-2 mb-2 px-2 py-[2px] md:px-3 md:py-1 border-[#4D3D39] ${
                        errors.sekolah && "border-[#ee1f1f]"
                      }`}
                      {...register("sekolah", {
                        required: true,
                        maxLength: 50,
                      })}
                    />
                    {/* Email */}
                    <label>E-Mail Peserta 1/Ketua</label>
                    <input
                      className={`rounded-full border-2 mb-2 px-2 py-[2px] md:px-3 md:py-1 border-[#4D3D39] ${
                        errors.email && "border-[#ee1f1f]"
                      }`}
                      {...register("email", { required: true, maxLength: 50 })}
                    />
                    <label>ID Line Peserta 1/Ketua</label>
                    <input
                      className={`rounded-full border-2 mb-2 px-2 py-[2px] md:px-3 md:py-1 border-[#4D3D39] ${
                        errors.idline && "border-[#ee1f1f]"
                      }`}
                      {...register("idline", { required: true, maxLength: 20 })}
                    />
                    <label>Nomor HP/WA Peserta 1/Ketua</label>
                    <input
                      className={`rounded-full border-2 mb-2 px-2 py-[2px] md:px-3 md:py-1 border-[#4D3D39] ${
                        errors.notelp && "border-[#ee1f1f]"
                      }`}
                      {...register("notelp", {
                        required: true,
                        minLength: 10,
                        maxLength: 13,
                      })}
                    />
                    <br />
                    <br />
                    {/* PESERTA 2 */}
                    <h2 className="font-bold mb-2">Anggota Kelompok</h2>
                    {/* Nama */}
                    <label>Nama Lengkap Peserta 2</label>{" "}
                    {errors.nama2?.type === "maxLength" && (
                      <span className="text-[#ee1f1f]">
                        Maksimal karakter adalah 50
                      </span>
                    )}
                    <input
                      className={`rounded-full border-2 mb-2 px-2 py-[2px] md:px-3 md:py-1 border-[#4D3D39] ${
                        errors.nama2 && "border-[#ee1f1f]"
                      }`}
                      {...register("nama2", { required: true, maxLength: 50 })}
                    />
                    {/* TTL */}
                    <label>Tempat, Tanggal Lahir Peserta 2</label>
                    {errors.ttl2?.type === "maxLength" && (
                      <span className="text-[#ee1f1f]">
                        Maksimal karakter adalah 100
                      </span>
                    )}
                    <input
                      className={`rounded-full border-2 mb-2 px-2 py-[2px] md:px-3 md:py-1 border-[#4D3D39] ${
                        errors.ttl2 && "border-[#ee1f1f]"
                      }`}
                      {...register("ttl2", { required: true, maxLength: 100 })}
                    />
                    {/* Jenis Kelamin */}
                    <label>Jenis Kelamin Peserta 2</label>
                    <select
                      {...register("gender2", { required: true })}
                      className="rounded-full border-2 mb-2 px-1 py-1 md:px-2 md:py-2 border-[#4D3D39] cursor-pointer"
                    >
                      <option value="">Select one</option>
                      <option value="Laki-laki">Laki-laki</option>
                      <option value="Perempuan">Perempuan</option>
                    </select>
                    <label>Asal Sekolah Peserta 2</label>
                    {errors.sekolah2?.type === "maxLength" && (
                      <span className="text-[#ee1f1f]">
                        Maksimal karakter adalah 50
                      </span>
                    )}
                    <input
                      className={`rounded-full border-2 mb-2 px-2 py-[2px] md:px-3 md:py-1 border-[#4D3D39] ${
                        errors.sekolah2 && "border-[#ee1f1f]"
                      }`}
                      {...register("sekolah2", {
                        required: true,
                        maxLength: 50,
                      })}
                    />
                    {/* Email */}
                    <label>E-Mail Peserta 2</label>
                    <input
                      className={`rounded-full border-2 mb-2 px-2 py-[2px] md:px-3 md:py-1 border-[#4D3D39] ${
                        errors.email2 && "border-[#ee1f1f]"
                      }`}
                      {...register("email2", { required: true, maxLength: 50 })}
                    />
                    <br />
                    {/* PESERTA 3 */}
                    {/* Nama */}
                    <label>Nama Lengkap Peserta 3</label>{" "}
                    {errors.nama3?.type === "maxLength" && (
                      <span className="text-[#ee1f1f]">
                        Maksimal karakter adalah 50
                      </span>
                    )}
                    <input
                      className={`rounded-full border-2 mb-2 px-2 py-[2px] md:px-3 md:py-1 border-[#4D3D39] ${
                        errors.nama3 && "border-[#ee1f1f]"
                      }`}
                      {...register("nama3", { required: true, maxLength: 50 })}
                    />
                    {/* TTL */}
                    <label>Tempat, Tanggal Lahir Peserta 3</label>
                    {errors.ttl3?.type === "maxLength" && (
                      <span className="text-[#ee1f1f]">
                        Maksimal karakter adalah 100
                      </span>
                    )}
                    <input
                      className={`rounded-full border-2 mb-2 px-2 py-[2px] md:px-3 md:py-1 border-[#4D3D39] ${
                        errors.ttl3 && "border-[#ee1f1f]"
                      }`}
                      {...register("ttl3", { required: true, maxLength: 100 })}
                    />
                    {/* Jenis Kelamin */}
                    <label>Jenis Kelamin Peserta 3</label>
                    <select
                      {...register("gender3", { required: true })}
                      className="rounded-full border-2 mb-2 px-1 py-1 md:px-2 md:py-2 border-[#4D3D39] cursor-pointer"
                    >
                      <option value="">Select one</option>
                      <option value="Laki-laki">Laki-laki</option>
                      <option value="Perempuan">Perempuan</option>
                    </select>
                    <label>Asal Sekolah Peserta 3</label>
                    {errors.sekolah3?.type === "maxLength" && (
                      <span className="text-[#ee1f1f]">
                        Maksimal karakter adalah 50
                      </span>
                    )}
                    <input
                      className={`rounded-full border-2 mb-2 px-2 py-[2px] md:px-3 md:py-1 border-[#4D3D39] ${
                        errors.sekolah3 && "border-[#ee1f1f]"
                      }`}
                      {...register("sekolah3", {
                        required: true,
                        maxLength: 50,
                      })}
                    />
                    {/* Email */}
                    <label>E-Mail Peserta 3</label>
                    <input
                      className={`rounded-full border-2 mb-2 px-2 py-[2px] md:px-3 md:py-1 border-[#4D3D39] ${
                        errors.email3 && "border-[#ee1f1f]"
                      }`}
                      {...register("email3", { required: true, maxLength: 50 })}
                    />
                    <br />
                    <br />
                    <label>Link Berkas (Lihat Panduan Guideline)</label>
                    <p className="text-xs italic">
                      Harus berupa link, total file tidak melebihi 10mb
                    </p>
                    <input
                      className={`rounded-full border-2 mb-2 px-2 py-[2px] md:px-3 md:py-1 border-[#4D3D39] ${
                        errors.berkas && "border-[#ee1f1f]"
                      }`}
                      {...register("berkas", {
                        required: true,
                      })}
                    />
                    <br />
                    <input
                      className="rounded-full border-2 mt-4 px-2 py-[2px] md:px-3 md:py-1 text-[#E46871] border-black cursor-pointer"
                      type="submit"
                      disabled={loading && true}
                    />
                  </form>
                </div>
              )}
            </>
          )}
        </>
        <Merchandise />
      </div>
    </>
  );
}
