import React from "react";
import { useNavigate } from "react-router-dom";

function Navbar() {
  const navigate = useNavigate();

  return (
    <div
      className={`fixed text-white w-screen flex justify-center h-12 md:h-16 lg:h-20 z-50 items-center
      bg-[#6E8867]`}
    >
      <div className="flex justify-between items-center w-full md:max-w-[2000px] mx-10 md:mx-20">
        <div className="flex gap-2 lg:gap-4 cursor-pointer">
          <div
            className="relative h-[40px] md:h-[50px] xl:h-[60px]"
            onClick={() => navigate("/")}
          >
            <img src="/img/amsa_ui_putih.png" className="h-full" alt="" />
          </div>
          <div
            className="relative h-[40px] md:h-[50px] xl:h-[60px]"
            onClick={() => navigate("/")}
          >
            <img src="/img/nmgbc.png" className="h-full" alt="" />
          </div>
        </div>
        <div className="relative hidden lg:flex gap-6 text-sm xl:text-xl font-Crusoe">
          <a href="/">Home</a>
          <a href="/#about">About</a>
          <a href="/#theme">Theme</a>
          <a href="/#timeline">Timeline</a>
          <a href="/#competitions">Competition</a>
          <a href="/#faqs">FAQs</a>
          <a href="/#sponsor">Our Sponsors</a>
        </div>
        <div>
          <button
            onClick={() => navigate("/registration")}
            className="bg-[#F4F4E3] text-[#4D3D39] text-xs md:text-sm xl:text-xl border-[#3AA1FF] border-2 px-3 py-2 xl:px-6 xl:py-3"
          >
            Register
          </button>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
