import React from "react";
import { Link } from "react-router-dom";

function Merchandise() {
  return (
    <section id="merch" className="relative bg-[#FFF6EE]">
      <div className="flex flex-col items-center pb-20 md:pb-28 gap-4 md:gap-6 lg:gap-10">
        <div
          data-aos="fade-up"
          className="flex flex-col items-center justify-center gap-3 md:gap-8 mb-8 md:mb-10 mt-24 mx-32"
        >
          <h1 className="font-bold font-CinzelDecorative text-[#4D3D39] text-2xl md:text-3xl lg:text-5xl text-center">
            merchandise
          </h1>
          <div className="flex flex-col items-center justify-center text-xs md:text-sm lg:text-lg text-center">
            <h2>
              T-Shirt • Totebag • Notebook • Lanyard • Enamel PIn • Sticker Pack
            </h2>
            <h2>(More colors available)</h2>
          </div>
        </div>
        <div className="w-2/3 lg:w-1/2">
          <img src="\img\Page8\merch.png" alt="" />
        </div>
        <Link
          data-aos="zoom-in"
          target="blank"
          to={"https://bit.ly/NMGBC24Merch"}
          className="flex rounded-[50px] gap-1 md:gap-2 border border-black bg-[#FEF8EA] px-3 py-2 md:px-6 md:py-3 text-xs md:text-sm xl:text-xl"
        >
          <p>Order</p>
          <img
            src="/img/Page8/cart.png"
            alt=""
            className="size-[12px] md:size-[14px] lg:size-[20px]"
          />
        </Link>
      </div>
    </section>
  );
}

export default Merchandise;
