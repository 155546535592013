import React, { Component } from "react";
import Slider from "react-slick";
import { BsArrowLeftCircle, BsArrowRightCircle } from "react-icons/bs";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class Dokumentasi extends Component {
  render() {
    const settings = {
      dots: true,
      fade: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      autoplay: true,
      slidesToScroll: 1,
      nextArrow: <BsArrowRightCircle />,
      prevArrow: <BsArrowLeftCircle />,
    };
    return (
      <div className="">
        <Slider {...settings}>
          <div className="flex items-center h-full">
            <img src={"/img/Dokumentasi/1.JPG"} />
          </div>
          <div>
            <img src={"/img/Dokumentasi/2.JPG"} />
          </div>
          <div>
            <img src={"/img/Dokumentasi/4.JPG"} />
          </div>
          <div>
            <img src={"/img/Dokumentasi/5.JPG"} />
          </div>
          <div>
            <img src={"/img/Dokumentasi/8.JPG"} />
          </div>
          <div>
            <img src={"/img/Dokumentasi/9.png"} />
          </div>
          <div>
            <img src={"/img/Dokumentasi/10.png"} />
          </div>
        </Slider>
      </div>
    );
  }
}
