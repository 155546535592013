import React from "react";
import { useNavigate } from "react-router-dom";

function Hero() {
  const navigate = useNavigate();

  return (
    // <section className="w-screen h-screen bg-[#A5BB9F] text-[#4D3D39] flex flex-col justify-between pt-12 md:pt-20">
    <section className="hero w-screen min-h-screen bg-[#A5BB9F] text-[#4D3D39] flex flex-col justify-between pt-12 md:pt-20">
      <img src="/img/Page1/Bunga atas.png" className="w-screen" alt="" />
      <div
        data-aos="flip-up"
        style={{ backgroundImage: "url(/img/Page6/bg.png)" }}
        className="shadow-md flex flex-col-reverse md:flex-row md:my-10 mx-10 lg:mx-24 px-10 py-14 md:py-20 rounded-[50px] gap-4 md:gap-10 xl:gap-16 items-center"
      >
        <div className="flex flex-col justify-center md:w-3/4 flex-grow">
          <div className="gap-2 lg:gap-3 flex flex-col">
            <h1 className="font-CinzelDecorative md:text-4xl lg:text-5xl xl:text-6xl">
              NMGBC 2024
            </h1>
            <p className="text-xs lg:text-sm xl:text-lg">
              The National Medical and General Biology Competition (NMGBC)
              headed by AMSA UI stands as Indonesia's paramount annual event,
              thorough crafted to unite and elevate the intellectual skill of
              high school students nationwide in the captivating domains of
              biology and medicine. This grand symposium unfolds through three
              competitions: the General & Medical Biology Quiz, the Public
              Poster Presentation, and the compelling Health Speech Contest, all
              set against the backdrop of the illustrious Universitas Indonesia.
              This distinguished platform not only amplifies knowledge but also
              serves as a gathering place, attracting enthusiastic participants
              from every corner of Indonesia, eager to exhibit their skill and
              passion for the complex field of biological and medical sciences.
            </p>
          </div>
          <div className="mt-6">
            <button
              onClick={() => navigate("/registration")}
              className="bg-[#6E8867] px-3 py-2 md:px-6 md:py-3 text-xs md:text-base text-white"
            >
              Register Now!
            </button>
          </div>
        </div>
        <div className="flex justify-end h-fit md:w-fit w-2/3">
          <img src="/img/nmgbc.png" alt="" />
        </div>
      </div>
      <img src="/img/Page1/Bunga bawah.png" className="w-screen" alt="" />
    </section>
  );
}

export default Hero;
