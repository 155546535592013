import React from "react";
import Hero from "../components/Hero";
import About from "../components/About";
import Theme from "../components/Theme";
import Timeline from "../components/Timeline";
import Competition from "../components/Competition";
import FAQ from "../components/FAQ";
import Sponsor from "../components/Sponsor";
import Merchandise from "../components/Merchandise";

function Home() {
  return (
    <div className="min-h-screen bg-[#BFEEFF] -z-10">
      {/* <div className="min-h-screen bg-[#BFEEFF] -translate-y-12 md:-translate-y-20 -z-10"> */}
      <Hero />
      <About />
      <Theme />
      <Timeline />
      <Competition />
      <Merchandise />
      <FAQ />
      <Sponsor />
    </div>
  );
}

export default Home;
